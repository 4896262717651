import { createEffect, onCleanup } from "solid-js";
import { isServer } from "solid-js/web";
import { useIsRouting, useLocation, useBeforeLeave } from "@solidjs/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
});

export const RoutingProgressBar = () => {
  if (isServer) return null;

  const isRouting = useIsRouting();
  const location = useLocation();
  let lastPathname = location.pathname;
  let progressStarted = false;

  useBeforeLeave((e) => {
    const from = e?.from?.pathname?.split("?")[0];
    const to = e?.to?.toString()?.split("?")[0];

    // ignore query param changes..
    if (from === to) return;

    NProgress.start();
    progressStarted = true;
  });

  createEffect(() => {
    const isActualRouteChange = location.pathname !== lastPathname;
    if (isRouting() && isActualRouteChange) {
      lastPathname = location.pathname;
      if (!progressStarted) {
        NProgress.start();
        progressStarted = true;
      }
    } else if (!isRouting()) {
      NProgress.done();
      progressStarted = false;
    }
  });

  onCleanup(() => {
    NProgress.done();
  });

  return null;
};

import type { Component } from "solid-js";
import { useSessionContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { chatBubbleBottomCenter } from "solid-heroicons/outline";
import { launchLiveChat } from "~/utils/livechat";

export const LiveChatLaunchButton: Component = () => {
  const { isPartner, session } = useSessionContext();
  let chatLauncher: HTMLButtonElement;

  return (
    <button
      aria-label="Launch customer support chat"
      ref={(ref) => (chatLauncher = ref)}
      onClick={() =>
        launchLiveChat(
          isPartner() && session()?.name ? session()?.name : "",
          // TODO - add company number/ID number to 
          // isPartner() && session.company_number ? session.company_number : "",
          "",
          () => {
            if (chatLauncher) chatLauncher.remove();
          }
        )
      }
      class="bg-roma-blue shadow-[0_0_25px_5px_rgb(0_0_0_/_0.2)] flex items-center justify-center fixed bottom-4 right-2.5 w-[56px] aspect-square rounded-full group hover:bg-white transition-colors z-[49] print:hidden"
    >
      <Icon
        path={chatBubbleBottomCenter}
        class="w-7 text-white group-hover:text-roma-blue group-hover:animate-pulse mt-0.5"
        stroke-width={2.5}
      />
    </button>
  );
};
